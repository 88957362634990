.customers-page {
  padding-right: 24px;

  &__top-line {
    display: flex;
    justify-content: space-between;

    p {
      color: var(--secondary);
      font-weight: bold;
      cursor: pointer;
    }
  }

}

.white-label-add-new-customer-modal {
  display: grid;
  column-gap: 24px;
  grid-template-columns: 1fr 1fr;
  margin-right: 8px;

  input {
    width: 99%;
  }
}