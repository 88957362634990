.footer {
  height: 60px;
  width: 100%;
  position: absolute;
  bottom: 0;
  display: flex;
  justify-content: space-between;
  background: var(--backgroundDark);
  align-items: center;
  padding: 0 280px 0 24px;
  box-sizing: border-box;

  @media (max-width: 500px) {
    display: none;
  }

  &__contact-details {
    color: var(--textLight);
  }

  &__links > * {
    color: var(--textLight);
    margin-left: 4px;
  }
}