.info-box {
  padding: 24px 16px;
  background-color: var(--background);
  width: 100%;
  border-radius: 0px 25px;
  margin-top: 24px;
  border: 1px solid var(--secondary);

  @media (max-width: 500px) {
    margin: 0 auto 24px auto;
    width: 95%;
  }

  h1 {
    font-family: 'Montserrat', sans-serif;
    margin: 0;
    margin-bottom: 16px;
    font-size: 2.4rem;
    font-weight: bold;
    color: var(--secondary);
  }

  > * {
    color: var(--text);
    margin-bottom: 16px;
  }
}