.wl-content-page-banners {
  position: relative;

  &__add {
    position: absolute;
    right: 40px;
    top: -70px;
    color: var(--secondary);
    cursor: pointer;
    font-weight: bold;
  }

  &__spinner {
    position: absolute;
    left: 50vw;
    height: 50vh;

    p {
      text-align: center;
      font-size: 2rem;
      font-weight: bold;
    }
  }

  &__banner-section {
    margin-top: 40px;

    tbody tr:hover {
      background: var(--backgroundHover);
    }
  }
}