.customer-page-markups {
  position: relative;

  &__save-btns {
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-top: 24px;
    align-items: flex-end;

    @media (max-width: 699px) {
      flex-direction: column;

      .multi-button {
        margin-bottom: 24px !important;

        > * {
          min-width: 150px !important;
        }
      }
    }
  }
}

.markups {
  &__radio-cont {
    display: grid;
    grid-template-columns: 244px 1fr;
    margin-top: 24px;

    @media (max-width: 699px) {
      grid-template-columns: 100%;
    }

    .multi-button {
      > * {
        width: 200px;

        @media (max-width: 699px) {
          max-width: 150px !important;
        }

      }
    }
  }

  &__input-wrapper {
    display: flex;
    column-gap: 24px;
    flex-wrap: wrap;
  }

  &__input-cont {
    display: flex;
    flex-wrap: wrap;
    column-gap: 24px;

    &__new-line {
      flex-basis: 100%;
      display: flex;
      flex-wrap: wrap;
      column-gap: 24px;
    }
  }

  &__weight-break-cont {

    &__new-line {
      flex-basis: 100%;
      display: flex;
      flex-wrap: wrap;
      column-gap: 24px;
      margin-top: 24px;
    }
  }
}