.shipment-type {
  &__header {
    margin-bottom: 8px;
  }

  &__warning {
    margin-bottom: 24px;
  }

  &__currency-picker {
    margin-top: -24px;
    margin-bottom: 24px;

    datalist {
      width: 120px;
    }
  }

  &__piece-counter {
    font-weight: bold;
    font-size: 1.4rem;
  }

  &__piece-totals {
    margin-top: -12px;
    margin-bottom: 24px;
  }

  &__add-piece {
    color: var(--primary);
    line-height: 28px;
    display: flex;
    cursor: pointer;
    width: fit-content;
    margin-top: -12px;

    svg {
      font-size: 3rem;
      color: inherit;
      margin-right: 4px;
      transition: transform .3s;
      transform: rotate(45deg);
      height: 12px;
      width: 12px;
    }

    &:hover svg {
      transform: rotate(135deg);
      transition: transform .3s;
    }
  }
}