.tracking-modal-content {
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 24px;
  row-gap: 24px;

  h3 {
    margin-bottom: 8px;
  }

  &__carrier-logo {
    grid-column: 1 / 3;
    margin-bottom: 12px;
  }

  &__booking-info {
    grid-column: 1 / 3;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

    span {
      color: var(--textMid);
    }
  }

  &__consignee-details {
    border-right: 1px dashed var(--disabledTodo);
  }

  &__downloads {
    &__btn-container {
      display: flex;

      > * {
        margin-right: 4px;
      }
    }

    span {
      color: var(--secondary);
      cursor: pointer;
      font-weight: bold;
    }
  }

  &__tracking-info {
    overflow: hidden;
    grid-column: 1 / 3;
    border-top: 1px dashed var(--disabledTodo);
  }
}