.wl-bookings {

  &__top-row {
    display: flex;
    margin-bottom: 12px;

    > * {
      min-width: 180px;
    }

    &__actions-cont {
      display: flex;
      flex-direction: column;
      row-gap: 8px;

      &__download {
        display: flex;
        column-gap: 10px;
        cursor: pointer;
      }
    }
  }

  &__spinner {
    margin: 0 auto;
    width: 150px;
    height: 200px;
    text-align: center;

    > {
      width: 100px;
      height: 100px;
    }
  }
}