.wl-export-modal {
  &__radio-btns {
    display: flex;
    flex-direction: column;
    margin-top: 12px;
    margin-bottom: 24px;

    > * {
      margin-bottom: 12px;
      display: flex;
      column-gap: 4px;

      input[type="radio"] {
        -webkit-appearance: none;
        appearance: none;
        background-color: var(--background);
        margin: 0;
        color: var(--text);
        width: 20px;
        height: 20px;
        border: 1px solid var(--text);
        border-radius: 50%;
        display: grid;
        place-content: center;
      }

      input[type="radio"]::before {
        content: "";
        width: 14px;
        height: 14px;
        border-radius: 50%;
        transform: scale(0);
        transition: 120ms transform ease-in-out;
        box-shadow: inset 1em 1em var(--form-control-color);
        background-color: var(--primary);
      }

      input[type="radio"]:checked::before {
        transform: scale(1);
      }
    }
  }

  &__inputs {
    display: flex;
    column-gap: 24px;

    @media (max-width: 800px) {
      flex-direction: column;
    }

    input {
      min-width: 200px;

      @media (max-width: 800px) {
        width: 100%;
      }
    }
  }
}