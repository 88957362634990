.btn,
.btn-default {
  font-family: 'Montserrat', sans-serif;
  font-weight: bold;
  border-radius: 5px;
  width: auto;
  height: 24px;
  background-color: transparent;
  border: 1px solid var(--textMid);
  border-radius: 5px;
  min-width: 127px;
  cursor: pointer;
  transition: all .5s;
  display: flex;
  justify-content: center;
  align-items: center;

  :first-child {
    margin-right: 4px;
  }

  &:hover {
    border: 1px solid var(--primary);
  }

  &:active {
    border: 2px solid var(--primary);
    transition: all 0s;
  }
}

.btn-primary {
  background-color: var(--primary);
  border: none;
  color: var(--textLight);

  &:hover {
    border: none;
    background-color: var(--primary75);
    color: var(--textLight);
  }

  &:active {
    background-color: var(--primary50);
    transition: all 0s;
  }
}

.btn-secondary {
  border: none;
  background-color: var(--secondary);
  color: var(--textLight);

  &:hover {
    border: none;
    background-color: var(--secondary75);
    color: var(--textLight);
  }

  &:active {
    background-color: var(--secondary50);
    transition: all 0s;
  }
}

.btn-disabled {
  border: none;
  background-color: var(--disabledTodo);
  color: var(--textLight);
  cursor: default;

  &:hover {
    border: none;
    color: var(--textLight);
  }
}

.btn-is-spinning {
  pointer-events: none;
}

.btn-large {
  min-width: 186px;
  height: 44px;
  width: auto;
  font-size: 2rem;
}

.btn-oversized {
  min-width: 186px;
  height: 238px;
  width: auto;
  font-size: 2.4rem;
  flex-direction: column;
  justify-content: center;
  border-radius: 25px;

  > * {
    font-size: 2.4rem;
    color: inherit;
  }

  > :last-child {
    margin-top: 12px;
  }
}