.finish-page {
  max-width: 2000px;
  margin: 0 auto;

  &__grid {
    display: grid;
    grid-template-columns: 1fr 50% 1fr;
    margin: 24px;
    position: relative;

    @media (max-width: 1160px) {
      grid-template-columns: 10% 1fr 10%;
    }
    @media (max-width: 750px) {
      grid-template-columns: 1px 1fr 1px;
    }

    &__content {
      grid-column: 2 / 3;

      &__header {
        font-family: 'Montserrat', sans-serif;
        font-weight: 700;
        font-size: 3rem;
        line-height: 37px;
        color: var(--success);
      }

      hr {
        height: 3px;
        background-color: var(--success);
        border: none;
      }

      &__main-grid {
        display: grid;
        grid-template-columns: auto 1fr;
        column-gap: 24px;

        &__right {
          p {
            margin-bottom: 8px;
          }

          span {
            color: var(--secondary);
            font-weight: 700;
          }

          &__drop-in-details {
            margin-bottom: 20px;

            p,
            h4 {
              margin-bottom: 4px;
            }
          }

          &__download-btns-container {
            display: flex;
            margin-bottom: 12px;

            @media (max-width: 650px) {
              grid-template-columns: 1fr;
              row-gap: 12px;
            }

            > * {
              @media (max-width: 650px) {
                width: 200px;
              }
            }

            button {
              margin-right: 12px;
            }
          }

          &__retry-span {
            cursor: pointer;
          }

          &__template-cont {
            margin-top: 24px;
            
            h2 {
              color: var(--secondary);
              width: 100%;
              border-bottom: 2px solid var(--secondary);
            }

            &__top-line {
              display: flex;
              cursor: pointer;

              :first-child {
                margin-right: 4px;
              }
            }

            &__content {
              &__save-cont {
                margin-top: 24px;

                input {
                  width: 100%;
                }

                > * {
                  margin-bottom: 8px;
                }
              }
            }
          }
        }
      }

      &__feedback {
        margin-top: 40px;
        h2 {
          color: var(--secondary);
          width: 100%;
          border-bottom: 2px solid var(--secondary);
        }

        p {
          margin-bottom: 12px;
        }
      }
    }

    &__important-notes {

      p {
        margin-bottom: 12px;
      }

      @media (max-width: 1400px) {
        grid-row: 2;
        grid-column: 2;
        margin: 64px 0;
      }
      @media (max-width: 1160px) {
        grid-row: 2;
        grid-column: 2;
        margin: 64px 0;
      }
    }
  }
}