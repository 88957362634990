.commodity {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  grid-column: 1 / 3;
  background: var(--background);
  border: 1px solid var(--secondary);
  box-sizing: border-box;
  border-radius: 0px 25px;
  margin: 12px 0;
  padding: 24px 24px 0 24px;

  &__error-message {
    margin-bottom: 24px;
    color: var(--error);
    width: 100%;
  }

  > * {
    margin-right: 12px;
  }

  input {
    width: 100%;
  }

  &__description {
    width: 283px;
  }

  &__code {
    width: 148px;
  }

  &__weight {
    width: 114px;
  }

  &__number-of-pieces {
    width: 130px;
  }

  &__value {
    width: 130px;
  }
}