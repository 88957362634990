.booking-history-table {
  border-collapse: collapse;
  overflow: hidden;

  .ghost-chevrons {
    opacity: 0;
    display: inline;
  }

  .ghost-chevrons > * {
    display: inline;
  }

  &__head-row {
    th > * {
      display: inline;
    }

    > *:hover .ghost-chevrons{
      opacity: .3;
      display: inline;
    }
  }

  tr {
    cursor: pointer;
    border: none;
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    transition: all .5s;

    > * {
      @media (max-width: 1000px) {
        overflow: hidden;
        max-width: 150px;
        text-overflow: ellipsis;
      }
      @media (max-width: 500px) {
        overflow: hidden;
        max-width: 33vw;
        text-overflow: ellipsis;
      }
    }
  }

  tbody tr:hover {
    background-color: var(--backgroundHover);
    border-radius: 5px;
    transition: all .5s;
  }
}