.mask {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: var(--backgroundDark);
  opacity: .5;
  z-index: 9;
}

.upload-page {
  display: grid;
  grid-template-columns: 1fr 442px 1fr;
  column-gap: 40px;

  @media (max-width: 1250px) {
    grid-template-columns: 1px 600px 1fr;
    column-gap: 0;
  }

  @media (max-width: 900px) {
    grid-template-columns: 1px 1fr;
    column-gap: 0;
  }

  &__content {
    margin: 0 auto;
    grid-column: 2;
  }

  &__btns-container {
    display: flex;
    justify-content: space-between;
    margin: 12px 0 24px 0;
    width: 442px;

    span {
      font-size: 2rem;
    }
    strong {
      font-size: 2.4rem;
    }

    @media (max-width: 500px) {
      width: auto;
      flex-direction: column;

      > * {
        width: 100% !important;
      }

      > :first-child {
        margin-bottom: 12px;
      }
    }
  }

  span {
    font-weight: normal;
    color: inherit;
  }

  &__info {
    @media (max-width: 900px) {
      grid-column: 2;
    }

  }

  &__btn-container {
    display: flex;
    flex-direction: column;
    align-items: center;

    > div:first-child {
      z-index: 1;
    }
  }
}

.drag-drop-zone {
  border: 2px dashed var(--secondary);
  border-radius: 25px;
  width: 207px;
  height: 200px;
  margin-top: -90px;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  color: var(--secondary);
  padding-bottom: 54px;

  @media (max-width: 499px) {
    display: none;
  }
}

.drag-drop-zone.inside-drag-area {
  margin-top: -35px;
  transition: all .7s;
}
.dropped-file {
  color: var(--secondary);
  padding: 3px;
  text-align: left;
  font-weight: bold;
}

.guidelines-modal-content {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  max-height: 50vh;
  overflow: auto;

  &__guideline {
    width: 100%;
    margin-bottom: 24px;

    p {
      margin-bottom: 4px;
    }

    > :first-child {
      color: var(--primary);
      font-weight: bold;
    }

    > :last-child {
      display: flex;
    }

    &__titles {
      margin-right: 24px;
    }

  }
}