.api-page {
  display: grid;
  grid-template-columns: 180px 1fr;
  column-gap: 24px;
  margin-bottom: 120px;

  p {
    margin-bottom: 4px;
  }

  header {
    grid-column: 1 / 3;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    align-items: center;
    margin-bottom: 24px;

    img {
      padding: 12px;
    }

    h1 {
      font-size: 3.6rem;
    }
  }

  .hide-examples-button {
    position: fixed;
    right: 0;
    top: 350px;
    background: var(--secondary);
    transform: rotate(90deg);
    transform-origin: top right;
    -webkit-transform-origin: 97% 20%;
    color: white;
    padding: 4px;
    border-radius: 5px;
    cursor: pointer;
    user-select: none;

    &:hover {
      transform: rotate(90deg) translateY(-1px);
    }

    &:active {
      transform: rotate(90deg) translateY(-2px);
    }

    &__chevron {
      display: inline-block;
      transform: rotate(180deg) translateY(-2px);
      fill: white;
    }
  }

  nav {
    position: fixed;
    width: 180px;
    top: 120px;

    .nav-links {
      border-right: 2px solid var(--tertiary);
      margin-top: 48px;
      margin-bottom: 24px;

      .nav-item {
        font-size: 2rem;
        height: 40px;
        line-height: 40px;
        padding-left: 12px;
        cursor: pointer;

        &:hover {
          background: var(--tertiary);
          color: white;
          transition: .5s all;
        }

        &--active {
          font-weight: bold;
          color: white;
          background: var(--tertiary);
          height: fit-content;
        }

        &__sub-menu {
          height: 0;
          overflow: hidden;

          &--active {
            height: fit-content;
          }

          &__item {
            padding-left: 12px;
            color: white;

            &:hover {
              color: var(--tertiary50);
            }
          }
        }
      }
    }

    .api-search {
      margin-left: 4px;
      position: relative;

      input {
        width:100%;
      }

      &__clear {
        position: absolute;
        width: fit-content;
        min-width: 22px;
        padding: 0 4px;
        height: 22px;
        top: 12px;
        right: -8px;
        background: var(--tertiary50);
        color: white;
        border-radius: 50%;
        text-align: center;
        cursor: pointer;

        &:hover {
          background: var(--tertiary);
        }
      }

      &__found-el-cont {
        display: flex;
        font-weight: bold;
        margin-top: -18px;
        justify-content: space-around;
        align-items: center;

        p {
          padding: 0 4px;
        }

        div {
          cursor: pointer;
          transition: all .3s;

          &:active {
            transform: translateX(2px);
            transition: all .3s;
          }

          &:first-child {
            &:active {
              transform: translateX(-2px);
              transition: all .3s;
            }
          }
        }
      }
    }
  }

  main {
    grid-column: 2;
    h2 {
      margin-top: 0;
    }
  }
}

// Folder wide classes
.api-url-box {
  background: var(--tertiary);
  border-radius: 5px;
  width: fit-content;
  padding: 4px 8px;
  color: white;
  margin: 24px 0;
}

.api-grid {
  display: grid;
  grid-template-columns: 3fr 2fr;
  column-gap: 24px;

  > h2 {
    grid-column: 1 / 4;
    font-size: 1.8rem;
    margin-bottom: 0;
  }

  &__table {
    display: grid;
    row-gap: 24px;
    column-gap: 12px;
    grid-column: 1;
    margin-bottom: 40px;
    height: fit-content;
    line-break: anywhere;

    &--error-response {
      margin-top: 24px;
    }

    ul {
      margin-bottom: 0;
    }

    span {
      color: var(--error);
      font-size: 1.4rem;
    }

    &__row {
      grid-column: 1 / 3;
      display: grid;
      grid-template-columns: 1fr 3fr;
      row-gap: 24px;
      column-gap: 12px;
      cursor: pointer;

      &--sub-row {
        grid-column: 1 / 3;
        display: none;
        grid-template-columns: 1fr 3fr;
        row-gap: 24px;
        column-gap: 12px;
        margin-left: 12px;
        cursor: pointer;
        background: url('../../../public/images/tab.png');
        background-repeat: no-repeat;
        background-position-y: 18px;
        position: relative;
      }
    }

    &__head {
      display: grid;
      grid-template-columns: 1fr 3fr;
      row-gap: 24px;
      column-gap: 12px;
      grid-column: 1;
    }

    &__body {
      display: grid;
      grid-template-columns: 1fr 3fr;
      row-gap: 24px;
      column-gap: 12px;
    }

    .expanded {
      display: grid;
      padding-left: 4px;
      border-left: 2px solid var(--tertiary50);
    }

    .not-expandable {
      margin-top: -12px;
      margin-bottom: -12px;
      padding-top: 24px;
      background-position-y: 42px;
      cursor: default;

      &:last-child {
        margin-bottom: 0;
      }

      .copy-route {
        position: absolute;
        right: 0;
        top: 12px;
        opacity: .5;
        cursor: pointer;
        font-weight: 14px;
        user-select: none;

        &:hover {
          opacity: 1;
        }

        &:active {
          transform: translateY(1px);
        }
      }
    }

    &__chevron {
      width: 12px;
    }
  }

  &__example {
    font-family: monospace;
    grid-column: 2;
    overflow: auto;
    margin-right: 4px;

    &--header {
      grid-row: 2;
    }

    &--body {
      grid-row: 4;
    }

    &--success-response {
      grid-row: 6;
    }

    &--success-response-tracking {
      grid-row: 4;
    }

    &--error-response {
      margin-top: 24px;
    }

    &__box {
      background: var(--tertiary50);
      border-radius: 5px;
      padding: 8px;
    }

  }
}