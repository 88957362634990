.template-modal {
  line-height: 20px;

  .modal__content {
    height: 80vh;
    margin-bottom: 48px;
  }

  &__template-steps {
    display: flex;
    flex-wrap: wrap;
    column-gap: 24px;
    row-gap: 12px;

    &__step {
      font-size: 20px;
      line-height: 24px;
      cursor: pointer;
      border-bottom: 2px solid transparent;

      &:hover {
        border-bottom: 2px solid var(--secondary);
      }

      &--active {
        font-weight: bold;
        color: var(--secondary);
        border-bottom: 2px solid var(--secondary);
      }
    }
  }

  hr {
    margin: 12px 0 24px 0;
  }

  h2 {
    margin-bottom: 24px;
  }

  &__form {
    margin: 0 12px;

    &__to-from {
      position: relative;
      display: grid;
      grid-template-columns: 50% 25% 24%;
      column-gap: 4px;

      @media (max-width: 760px) {
        display: flex;
        flex-direction: column;
      }

      &__checkbox {
        grid-column: 1 / 4;
        margin-top: -16px;
        margin-bottom: 24px;
      }
    }
  }
}

.template-modal-shipment-details,
.template-modal-collection-details,
.template-modal-shipper-details,
.template-modal-consignee-details,
.template-modal-broker-details,
.template-modal-preferences {
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 24px;

  input {
    width: 100%;
  }

}

.template-modal__country {
  grid-column: 1 / 3;
}

.template-modal-spinner {
  display: flex;
  align-items: center;
}