.contact-page {
  width: 50%;
  margin: 60px auto 120px auto;

  @media (max-width: 900px) {
    width: 80%;
  }

  .horizontal-card {
    margin-top: 24px;
    margin-bottom: 24px;
  }

  &__contact-entry__details {
    display: flex;

    @media (max-width: 500px) {
      flex-direction: column;
    }

    > * {
      margin-right: 24px;
    }
  }

  .horizontal-card {
    @media (max-width: 500px) {
      margin-left: -44px;
      margin-right: -44px;
    }
  }
}