.header {
  font-family: 'Montserrat', sans-serif;
  position: relative;
  width: 100vw;
  height: 145px;

  &__logo {
    position: fixed;
    margin-left: 20px;
    margin-top: 24px;
    cursor: pointer;
    width: 185px;
    transition: all 1s;

    &--scroll {
      width: 120px;
      transition: all 1s;
    }
  }
}

.mobile-header {
  font-family: 'Montserrat', sans-serif;
  width: 100vw;
  height: 94px;
  background: var(--tertiary);
  display: flex;
  align-items: center;

  &__logo {
    width: 110px;
    margin-left: 24px;
    margin-right: 4px;
    cursor: pointer;
  }

  &__heading {
    font-weight: bold;
    font-size: 2rem;
    color: var(--textLight);
    width: 100%;
    text-align: center;
  }
}

.header__angle-block {
  position: absolute;
  width: 400%;
  height: 145px;
  left: 200px;
  top: 0;
  background: var(--tertiary);
  clip-path: polygon(0 0, 100% 0%, 100% 11%, 3% 100%);
}

.header__link-container {
  padding: 38px 0 0 0;

  &__main-links {
    position: absolute;
    text-align: center;
    width: 100%;

    > *:not(:last-child) {
      margin-right: 44px;

      @media (max-width: 1250px) {
        margin-right: 12px;
      }
    }
  }
}

.header__link {
  position: relative;
  display: inline;
  color: var(--textLight);
  font-size: 2rem;
  cursor: pointer;

  &::before {
    content: ' ';
    position: absolute;
    width: 0%;
    left: 50%;
    transform: translateX(-50%);
    height: 2px;
    bottom: -8px;
    background: var(--background);
    transition: all .3s ease-out;
  }

  &:hover::before {
    content: ' ';
    position: absolute;
    width: 50%;
    left: 50%;
    transform: translateX(-50%);
    height: 2px;
    bottom: -8px;
    background: var(--background);
    transition: all .3s ease-out;
  }

  &--disabled {
    position: relative;
    display: inline;
    color: var(--textLight);
    font-size: 2rem;
    cursor: default;
    opacity: .5;
    pointer-events: none;
  }

  &--active {
    position: relative;
    display: inline;
    color: var(--textLight);
    font-size: 2rem;
    cursor: pointer;
    font-weight: bold;

    &::before {
      content: ' ';
      position: absolute;
      width: 50%;
      left: 50%;
      transform: translateX(-50%);
      height: 2px;
      bottom: -8px;
      background: var(--background);
    }
  }
}

.header__account-menu {
  text-align: right;
  padding-right: 4%;
}

