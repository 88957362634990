.tax-number-error-message {
  grid-column: 1 / 3;
  margin: -12px 0 24px 0;
  color: var(--error);
}

.edn-description {
  grid-column: 1 / 3;
  margin-bottom: 24px;
  margin-top: -12px;
}

.ccn-description {
  grid-column: 1 / 3;
  margin-bottom: 24px;
  margin-top: -12px;
}

@media (min-width: 606px) {
  .edn-checkbox {
    margin-top: 24px;
  }

  .ccn-checkbox {
    margin-top: 24px;
  }
}