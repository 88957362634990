.alert {
  font-family: 'Lato', sans-serif;
  display: grid;
  grid-template-columns: auto 1fr;
  width: auto;
  height: auto;
  border-width: 1px;
  border-style: solid;
  border-radius: 5px;
  margin: auto;
  color: var(--text) !important;
  font-weight: 400 !important;

  span {
    color: inherit !important;
    font-weight: inherit !important;
  }

  &--error {
    background: var(--error50);
    border-color: var(--error);
  }

  &--warning {
    background: var(--warning50);
    border-color: var(--warning);
  }

  &--info {
    background: var(--success50);
    border-color: var(--success);
  }

  &__text {
    margin: 14px 12px 14px 0;
    text-align: left;
    line-height: 20px;
  }
}