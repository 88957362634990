.wl-booking-modal {
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 24px;
  row-gap: 24px;

  h3 {
    margin-bottom: 8px;
  }

  &__carrier-logo {
    grid-column: 1 / 3;
    margin-bottom: 12px;
  }

  &__booking-info {
    grid-column: 1 / 3;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

    span {
      color: var(--textMid);
    }
  }

  &__markup {
    grid-column: 1 / 2;

    &__content {
      display: flex;
      column-gap: 24px;
      margin-bottom: 24px;

      border-right: 1px dashed var(--disabledTodo);

      &__divide {
        border-left: 1px solid var(--disabledTodo);
        width: 1px;
      }
    }
  }

  &__downloads {
    &__btn-container {
      //grid-column: 2 / 3;
      display: flex;

      > * {
        margin-right: 4px;
      }
    }

    span {
      color: var(--secondary);
      cursor: pointer;
      font-weight: bold;
    }
  }

  &__tracking-info {
    overflow: hidden;
    grid-column: 1 / 3;
    border-top: 1px dashed var(--disabledTodo);
  }
}