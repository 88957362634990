.faq-page {
  width: 50%;
  margin: 60px auto 120px auto;

  @media (max-width: 900px) {
    width: 80%;
  }

  &__spinner {
    margin: 0 auto;
    display: block;
  }

  .horizontal-card {
    @media (max-width: 500px) {
      margin-left: -44px;
      margin-right: -44px;
    }
  }

  &__faq {
    overflow: hidden;
    position: relative;
    cursor: pointer;

    h3 {
      max-width: 90%;

      @media (max-width: 500px) {
        max-width: 80%;
      }
    }

    &--open {
      .faq-content {
        transform: scaleY(1);
        transition: all .3s;
        transform-origin: top;
        padding-right: 12px;
      }

      h3 {
        color: var(--primary);
      }
    }

    &--closed {
      .faq-content {
        transform: scaleY(0);
        transition: all .3s;
        transform-origin: top;
        max-height: 0;
        padding-right: 12px;
      }
    }
  }
}