.shipment-details {
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 12px;
  margin-bottom: 24px;

  .details-container__title {
    grid-column: 1 / 3;
  }

  &__reason-for-export-cont {
    &__text {
      color: var(--primary);
      margin: -12px 0 24px 0;
    }
  }

  &__deferment {
    grid-column: 1 / 3;

    &__checkbox {
      @media (min-width: 550px) {
        margin-top: -12px;
      }
    }

    &__input {
      @media (min-width: 550px) {
        margin-top: 36px;
        width: 50%;
      }
    }

    &--active {
      background: var(--tertiary50);
      margin: 0 -12px;
      padding: 0 12px 12px 12px;
      border-radius: 0 25px;

      @media (max-width: 550px) {
        padding: 12px;
      }
    }
  }

  &__checkbox > * {
    @media (min-width: 550px) {
      transform: translateY(19px);
    }
  }

  &__form-element {
    @media (max-width: 550px) {
      grid-column: 1 / 3;
    }
  }

  &__currency-container {
    display: grid;
    grid-auto-flow: column;
    grid-template-columns: repeat(5, 1fr);
  }

  &__currency-symbol{
    display: grid;
    justify-content: center;
    align-content: center;
  }
}


