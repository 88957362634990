.template-modal-commodities__sub-commodities-container__totals-grid {
  margin-top: 24px;
  display: grid;
  grid-template-columns: auto auto;
  row-gap: 8px;

  span {
    opacity: .7;
  }

  .commodity-total-red {
    color: var(--error);
  }
}