.carrier-container {
  position: relative;
  display: grid;
  grid-template-columns: 1fr 155px;
  height: 112px;
  margin-bottom: 24px;

  &--disabled {
    opacity: 0.8;
  }

  &__drop-down-mask {
    position: fixed;
    left: 0;
    top: 0;
    height: 100vh;
    width: 100vw;
    background: black;
    opacity: .1;
    z-index: 1;
  }

  &__drop-down {
    position: relative;
    width: 550px;
    background: var(--background);
    z-index: 2;
    float: right;
    margin-top: 12px;
    margin-right: -14px;
    border-bottom-left-radius: 25px;
    height: 0;
    overflow: hidden;
    border: 0 solid var(--secondary);
    transition: .2s padding;
    -ms-overflow-style: none;
    scrollbar-width: none;

    &::-webkit-scrollbar {
      display: none;
    }

    @media (max-width: 650px) {
      width: 90vw;
    }

    &__header {
      color: var(--secondary);
      font-weight: bold;
      margin-bottom: 8px;
      font-size: 1.8rem;
      font-family: 'Montserrat', sans-serif;

      &:not(:first-child) {
        margin-top: 24px;
      }
    }
  }

  &__extras {
    display: flex;
    flex-direction: column-reverse;
    justify-content: flex-start;
    grid-column: 2;
    width: 155px;
    height: 112px;
    overflow: hidden;
    border-top-right-radius: 25px;
    margin-left: -24px;
    position: relative;

    > * {
      border: 2px solid var(--secondary);
      height: 51%;
    }

    :last-child {
      border-top-right-radius: 25px;
    }
  }
}

.carrier-insurance-block {
  display: flex;
  align-items: center;
  padding-left: 34px;
  background: var(--tertiary50);
  cursor: pointer;
  position: absolute;
  top: 0px;
  right: 0;
  width: 155px;

  @media (max-width: 650px) {
    display: none;
  }

  &--disabled {
    opacity: .8;
    pointer-events: none;
    border: transparent 2px solid;
    background-color: var(--disabledTodo) !important;
  }

  &--disabled {
    pointer-events: none;
    border: var(--disabledTodo) 2px solid;
    background-color: var(--disabledTodo) !important;
  }

  > * {
    color: var(--textLight);
  }
}

.carrier-ddp-block {
  display: flex;
  align-items: center;
  padding-left: 34px;
  background: var(--tertiary);
  cursor: pointer;
  position: absolute;
  bottom: 0px;
  right: 0;
  width: 155px;

  @media (max-width: 650px) {
    display: none;
  }

  &--disabled {
    pointer-events: none;
    border: var(--disabledTodo) 2px solid;
    background-color: var(--disabledTodo) !important;
  }

  > * {
    color: var(--textLight);
  }
}

.carrier {
  grid-column: 1;
  grid-row: 1;
  position: relative;
  margin: 0;
  cursor: pointer;
  padding: 12px;
  height: 112px;

    @media (max-width: 650px) {
      width: 95vw;
    }

  &:hover .carrier-container__drop-down {
    height: fit-content;
    max-height: 500px;
    padding: 8px;
    border: 2px solid var(--secondary);
    overflow-y: auto;
  }

  &--disabled {
    pointer-events: none;
    background: var(--disabledTodo);
    border-color: var(--disabledTodo);

    .carrier__select {
      color: var(--textMid);

      svg {
        fill: var(--textMid) !important;
      }
    }
  }

  &__info {
    position: absolute;
    top: 0;
    right: 0;
    font-family: 'Lemon', cursive;
    color: var(--primary);
    margin-right: 11px;
    margin-top: 4px;
    cursor: pointer;
    padding: 6px;
    transition: all .3s;

    &:hover {
      position: absolute;
      top: 0;
      right: 0;
      font-family: 'Lemon', cursive;
      color: var(--primary);
      transform: scale(1.5);
      transition: transform .3s;
      cursor: pointer;
    }
  }

  &__grid {
    display: flex;
    align-content: center;
    height: 100%;

    &__img-cont {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      text-align: center;
      margin-right: 24px;

      img {
        border-radius: 5px;
      }
    }

    &__base-price {
      font-family: 'Montserrat', sans-serif;
      font-weight: 700;
      font-size: 2rem;
      line-height: 24px;
    }

    &__text-content {
      display: flex;
      width: 60%;

      @media (max-width: 650px) {
        display: none;
      }

      &__item {
        margin-right: 24px;

        &__title {
          opacity: .6;

          span {
            opacity: 1;
          }

          &:hover {
            opacity: 1;
          }
        }
      }
    }
  }

  &__select {
    position: absolute;
    bottom: 8px;
    right: 8px;
    color: var(--primary);
    cursor: pointer;
    transform: translateX(-2px);
    transition: .3s transform;
    font-weight: bold;
  }
}

.courier-modal {
  p {
    margin-bottom: 12px;
  }

  &__sub-heading {
    font-weight: 700;
    margin: 24px 0 8px 0;
  }
}