.api-table-row {
  grid-column: 1 / 3;
  display: grid;
  grid-template-columns: 1fr 3fr;
  row-gap: 24px;
  column-gap: 12px;
  cursor: pointer;
  position: relative;

  &--expanded {
    border-left: 2px solid var(--tertiary50);
    padding-left: 4px;
  }

  &--sub-row {
    margin-left: 4px;
    padding-left: 4px;
    margin-top: -24px;
    padding-top: 24px;
    border-left: 2px solid var(--tertiary50);
    background: url('../../../../public/images/tab.png');
    background-repeat: no-repeat;
    background-position-y: 40px;

    .copy-route {
      display: none;
      position: absolute;
      right: 0;
      top: 12px;
      opacity: .5;
      cursor: pointer;
      font-weight: 14px;
      user-select: none;

      &:hover {
        opacity: 1;
      }

      &:active {
        transform: translateY(1px);
      }
    }

    &:hover .copy-route {
      display: block;
    }
  }

  &__found {
    background: var(--warning50);
    border-radius: 5px;
    padding: 4px;
  }
}