.wl-content-page {

  &__spinner {
    margin: 0 auto;
    width: 150px;
    height: 200px;
    text-align: center;

    > {
      width: 100px;
      height: 100px;
    }
  }

  h3 {
    margin: 0;
  }

  hr {
    border: 1px solid var(--tertiary);
  }

  &__nav {
    display: flex;
    margin-top: -9px;

    &__item {
      width: 128px;
      height: 40px;
      line-height: 38px;
      border: 1px solid var(--tertiary);
      text-align: center;
      cursor: pointer;

      @media (max-width: 699px) {
        max-width: 80px;
      }

      &--active {
        background: var(--tertiary);
        color: var(--textLight);
        font-weight: bold;
      }
    }
  }

  &--closed {
    max-height: 73px;
    overflow: hidden;
    cursor: pointer;

    &:hover {
      background: var(--backgroundHover);
    }
  }
}