.quote-filter {
  grid-column: 2;
  display: flex;
  margin: 0;
  margin-left: -48px;
  padding: 12px 18px 12px 48px;

  @media (max-width: 1050px) {
    margin-left: 0;
    margin-bottom: 24px;
  }

  @media (max-width: 650px) {
    position: relative;
    grid-column: 2;
    grid-row: 1;
    width: 90vw;
    display: grid;
    margin: 0 12px 24px 12px;
    padding: 8px;
  }

  &__filter-switch {
    margin-right: 24px;

    @media (max-width: 650px) {
      grid-column: 1 / 3;
    }
  }

  &__filter-block {
    margin-right: 24px;

    @media (max-width: 650px) {
      margin-right: 4px;
      margin-bottom: 0;
      grid-row: 2;
    }
  }

  &__heading {
    font-weight: bold;
    margin-bottom: 4px;

    &:not(:first-child) {
      margin-top: 12px;
    }
  }

  &__radio {
    margin-bottom: 4px;
  }
}





