.account-addresses {
  max-width: 1500px;
  margin-right: 12px;

  hr {
    border-color: var(--tertiary);
    margin-bottom: 24px;
  }

  .spinner {
    margin-left: 45%;
  }

  &__top-row {
    display: flex;
    justify-content: space-between;
    margin-bottom: 24px;

    &__add-new-address-btn {
      color: var(--primary);
      white-space: nowrap;
      cursor: pointer;
    }
  }
}