html {
  font-size: 62.5%;
}

body {
  margin: 0;
  font-family: 'Lato', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 1.6rem;
}

* {
  color: var(--text);
  box-sizing: border-box;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: 'Montserrat', sans-serif;
}

h1 {
  font-size: 2rem;
}

h2 {
  font-size: 1.8rem;
}

p {
  margin: 0;
}

.primary-highlight-text {
  color: var(--primary);
}

a {
  text-decoration: none;
  color: var(--primary);
  cursor: pointer;
}

strong {
  color: inherit;
  font-size: inherit;
}

.full-screen-mask {
  position: fixed;
  height: 100%;
  width: 100%;
  left: 0;
  top: 0;
  background: #00000075;
  z-index: 99;
}

.full-screen-clear-mask {
  position: fixed;
  height: 100%;
  width: 100%;
  left: 0;
  top: 0;
  background: transparent;
  z-index: 99;
}

.default-page-grid {
  display: grid;
  grid-template-columns: 1fr 50% 1fr;
  max-width: 2000px;
  margin: 24px auto 120px auto;

  @media (max-width: 1360px) {
    grid-template-columns: 1fr 60% 1fr;
  }
  @media (max-width: 1140px) {
    grid-template-columns: 1fr 70% 1fr;
  }
  @media (max-width: 1000px) {
    grid-template-columns: 1fr 90% 1fr;
  }
  @media (max-width: 550px) {
    grid-template-columns: 1fr 100% 1fr;
    margin: 0 -22px 120px -24px;
  }
}

.vertical-card {
  background: var(--background);
  border-radius: 0px 25px;
  height: fit-content;
  padding: 24px 18px;
  margin: 0 48px;
  border: var(--secondary) 2px solid;
}

.card-header {
  font-family: 'Montserrat', sans-serif;
  font-weight: 700;
  font-size: 2rem;
  line-height: 24px;
  color: var(--secondary);
  margin-bottom: 24px;
}

.horizontal-card {
  background: var(--background);
  border: 2px solid var(--primary);
  box-sizing: border-box;
  border-radius: 0px 25px;
  margin: 0 -68px;
  padding: 24px 18px;

  @media (max-width: 550px) {
    margin: 0 -44px;
  }
}

.horizontal-card__title {
  font-family: 'Montserrat', sans-serif;
  font-weight: 700;
  font-size: 2rem;
  line-height: 24px;
  color: var(--text);
  margin-bottom: 24px;
  grid-column: 1 / 3;
}

@keyframes flash {
  0% {
    color: black;
  }
  50% {
    color: var(--success)
  }
}

li {
  margin-bottom: 4px;
}

.quote-details-summary {
  margin-top: 24px;
}

.quote-details-summary > * {
  display: flex;
  justify-content: space-between;
}

.add-commodity-icon {
  color: var(--primary);
  line-height: 28px;
  display: flex;
  cursor: pointer;
  width: fit-content;
  height: fit-content;
  margin-top: -12px;

  svg {
    font-size: 30px;
    color: inherit;
    margin-right: 4px;
    transition: transform .3s;
    transform: rotate(45deg);
    height: 12px;
    width: 12px;
  }

  &:hover svg {
    transform: rotate(135deg);
    transition: transform .3s;
  }
}

.bottom-margin-24 {
  margin-bottom: 24px;
}