.modal-mask {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: var(--backgroundDark);
  opacity: .5;
  z-index: 9;
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  margin: 5% 50%;
  transform: translateX(-50%);
  padding: 24px;
  background-color: var(--background);
  border-radius: 25px;
  z-index: 101;
  overflow: hidden;
  max-width: 1500px;

  @media (max-width: 500px) {
    position: fixed;
    margin: 0;
    width: 100vw !important;
    height: 100vh;
    transform: translateX(0);
    padding: 24px;
    background-color: var(--background);
    border-radius: 0;
    z-index: 10;
    overflow: hidden;
    display: flex;
    flex-direction: column;
  }

  &__header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 40px;
  }

  &__title {
    font-family: 'Montserrat', sans-serif;
    width: 100%;
    font-weight: bold;
    font-size: 2.4rem;
  }

  &__close {
    svg {
      cursor: pointer;
      transition: transform .3s;
    }

    &:hover svg {
      cursor: pointer;
      transform: rotate(90deg);
      transition: transform .3s;
    }
  }

  &__content {
    max-height: 55vh;
    overflow-y: auto;

    @media (max-width: 500px) {
      max-height: 100%;
    }
  }

  &__footer {
    margin: 24px -24px 0 -24px;
    padding-top: 12px;
    padding-right: 24px;
    border-top: 1px solid var(--disabledTodo);
    display: flex;
    justify-content: flex-end;
    background-color: var(--background);

    @media (max-width: 500px) {
      margin-top: 40px;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      flex-grow: 1;
    }

    > * {
      margin: 8px;
    }
  }
}