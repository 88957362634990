.white-label-upload {
  display: grid;
  column-gap: 24px;
  grid-template-columns: 145px 1fr;
  align-items: center;
  margin-right: 70px;
  margin-bottom: 40px;

  h3 {
    grid-column: 1 / 3;
  }

  &__alert {
    grid-column: 1 / 3;
    margin-bottom: 12px;
    margin-top: 24px;
    width: 100%;
  }

  &__blurb {
    grid-column: 1 / 3;
  }

  .drag-and-drop-box {
    grid-row: 2;
    width: 145px;
    height: 145px;
    padding: 8px;
    text-align: center;
    margin-right: 24px;
  }

  &__btns {
    grid-row: 2;
    grid-column: 2;
  }

  .btn {
    margin: 4px 0;
  }

  > p {
    grid-row: 3;
    margin: 8px 0;
  }

  > img {
    width: 185px;
    grid-row: 4;
  }
}