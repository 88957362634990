.ami-checkbox {
  display: block;
  position: relative;
  padding-left: 28px;
  font-size: 1.6rem;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  line-height: 21px;

  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;

    &:checked ~ .checkmark {
      background-color: var(--background);
      border: var(--primary) solid 1px;

      &:after {
        display: block;
      }
    }
  }

  .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 20px;
    width: 20px;
    border: var(--text) solid 1px;
    border-radius: 2px;
    cursor: pointer;

    &:after {
      content: "";
      position: absolute;
      display: none;
      left: 6px;
      top: 1px;
      width: 5px;
      height: 10px;
      border: solid var(--primary);
      border-width: 0 3px 3px 0;
      -webkit-transform: rotate(45deg);
      -ms-transform: rotate(45deg);
      transform: rotate(45deg);
    }
  }

  &:hover input ~ .checkmark {
    border: var(--primary) solid 2px;
  }

  &--disabled {
    color: var(--textMid);

    &:hover input ~ .checkmark {
      border: var(--textMid) solid 1px;
    }

    .checkmark {
      cursor: default;
      background-color: var(--disabledTodo);
      border: var(--textMid) solid 1px;
    }

    input:checked ~ .checkmark {
      background-color: var(--disabledTodo);
      border: var(--textMid) solid 1px;
    }

    .checkmark:after {
      border: solid var(--textMid);
      border-width: 0 3px 3px 0;
    }
  }
}