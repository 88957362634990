.commodities-card {
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 12px;
  margin-top: 24px;
  margin-bottom: 24px;

  &__sub-title,
  &__commodity-text {
    grid-column: 1 / 3;
  }

  &__sub-title {
    font-weight: 700;
    font-size: 2rem;
    line-height: 24px;
    margin-bottom: 12px;
  }

  &__phytosanitary-check {
    margin-bottom: 24px;
    grid-column: 1 / 3;
  }
}
