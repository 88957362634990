.customer-page-details {

  form {
    display: grid;
    grid-template-columns: 250px 250px;
    grid-gap: 12px;

    @media (max-width: 699px) {
      grid-template-columns: 100%;
    }

    .input {
      width: 100%;
    }
  }
}