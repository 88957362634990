.ami-date-picker {
  position: relative;

  * {
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none;   /* Chrome/Safari/Opera */
    -khtml-user-select: none;    /* Konqueror */
    -moz-user-select: none;      /* Firefox */
    -ms-user-select: none;       /* Internet Explorer/Edge */
    user-select: none;           /* Non-prefixed version, currently */
  }

  input[type="date"]::-webkit-calendar-picker-indicator {
      display: none;
      -webkit-appearance: none;
  }

  &__input {
    input {
      font-family: 'Lato', sans-serif;
      padding-left: 8px;
      font-size: 1.4rem;
      height: 32px;
      width: auto;
      border: 1px solid var(--disabledTodo);
      background: var(--background);
      color: var(--text);
      border-radius: 5px;
      cursor: pointer;

      &:focus {
        outline: none;
        border: 1px solid var(--primary);
      }
    }

    &--large input {
      height: 44px;
      font-size: 1.6rem;
    }

    &--disabled input {
      background: var(--disabledTodo);
      pointer-events: none;
    }

  }

  &__container {
    position: fixed;
    top: 0;
    left: 0;
    margin-left: 50%;
    margin-top: 50vh;
    transform: translate(-50%, -50%);
    width: 408px;
    min-height: 350px;
    background: var(--background);
    border-radius: 5px;
    overflow: hidden;
    padding: 20px 20px;
    border: 1px solid var(--disabledTodo);
    z-index: 100;

    @media (max-width: 500px) {
      position: fixed;
      width: 90%;
      min-height: 350px;
      background: var(--background);
      border-radius: 5px;
      overflow: hidden;
      padding: 20px 20px;
      border: 1px solid var(--disabledTodo);
      z-index: 100;
      top: 20%;
      margin-left: 50%;
      margin-top: 50%;
      transform: translate(-50%, -50%);
    }

    &__head {
      width: 100%;
      height: 53px;
      display: flex;
      justify-content: space-between;

      &__button {
        width: 45px;
        height: 100%;
        box-sizing: border-box;
        position: relative;

        &__inner {
          height: 35px;
          width: 35px;
          border-radius: 100%;
          position: absolute;
          top: 50%;
          left: 50%;
          margin-left: -17px;
          margin-top: -17px;
          display: flex;
          justify-content: center;
          align-items: center;

          &:hover {
            cursor: pointer;
            background: var(--tertiary50);

            > span {
              border-color: var(--text) !important;
            }
          }
        }
      }

      &__container {
        width: 120px;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;

        &--year {
          width: 100%;
          height: 30px;
          font-size: 2.7rem;
          color: var(--text);
          text-align: center;
        }
        &--month {
          width: 100%;
          height: 15px;
          font-size: 1.3rem;
          color: var(--text);
          text-align: center;
        }
      }
    }
    &__body {
      width: 100%;
      margin-top: 10px;
    }
  }
}

.calender-container__head,
.calender-container__head__name,
.calender-container__body,
.calender-day-container__day span,
.calender-day-container__day,
.calender-day-container,
.calender-container {
  position: relative;
  display: block;
  float: left;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.calender-container {
  width: 100%;
  height: 100%;
}


.calender-container__head {
  height: 30px;
  width: 100%;
  margin-top: 10px;

  &__name {
    width: 14.285%;
    height: 30px;
    line-height: 30px;
    color: var(--text);
    font-size: 1.4rem;
    text-align: center;
    font-weight: bold;
  }
}
.calender-container__body {
  height: 270px;
  width: 100%;
}

.calender-day-container {
  width: 14.285%;
  height: 16.6666%;

  &__day {
    width: 100%;
    height: 100%;
    color: var(--text);
    text-align: center;

    span {
      color: var(--text);
      width: 30px;
      height: 30px;
      margin-top: -15px;
      margin-left: -15px;
      left: 50%;
      top: 50%;
      border-radius: 100%;
      line-height: 30px;
      font-size: 1.6rem;

      &:hover {
        cursor: pointer;
        background: var(--primary50);
        color: var(--textLight);
      }

      &:focus {
        outline: 2px solid var(--primary)  ;
      }
    }
  }

  &.disabled {
    pointer-events: none;

    .calender-day-container__day span {
      color: var(--disabledTodo);
      background: var(--background) !important;
    }
  }

  &.highlight .calender-day-container__day span {
    background: var(--tertiary50);
  }

  &.highlight-pink .calender-day-container__day span {
    background: var(--primary);
    color: var(--textLight);
  }
}