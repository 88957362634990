.manifest-page {
  padding: 0 68px 68px 68px;

  @media (max-width: 1250px) {
    padding: 0 34px 68px 34px;
  }

  @media (max-width: 500px) {
    padding: 0 12px 68px 12px;
  }
}