.price-table {
  margin-top: 24px;
  margin-bottom: 24px;

  > * {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }

  &__margin-top {
    margin-top: 12px;
  }

  &--sub-font {
    font-size: 1.4rem;
    color: var(--textMid);
    white-space: nowrap;

    span {
      font-size: inherit;
      color: inherit;
    }
  }

  &__total-title {
    font-family: 'Montserrat', sans-serif;
    font-size: 2.4rem;
  }

  &__total-value {
    font-family: 'Montserrat', sans-serif;
    font-size: 2.4rem;
    font-weight: 700;
  }
}