.ami-radio {
  display: block;
  position: relative;
  padding-left: 28px;
  font-size: 1.6rem;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  line-height: 21px;

  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }

  &:hover input ~ .dot {
    border: var(--primary) solid 2px;
  }

  .dot {
    position: absolute;
    top: 0;
    left: 0;
    height: 20px;
    width: 20px;
    border: var(--text) solid 1px;
    border-radius: 50%;
    cursor: pointer;

    &:after {
      content: "";
      position: absolute;
      display: none;
      background-color: var(--primary);
      width: 16px;
      height: 16px;
      border: solid var(--disabledTodo) 1px;
      border-radius: 50%;
    }
  }

  input:checked ~ .dot {
    background-color: var(--background);
    border: var(--primary) solid 1px;

    &:after {
      display: block;
    }
  }

  &--disabled {
    color: var(--textMid);

    &:hover input ~ .dot {
      border: var(--disabledTodo) solid 1px;
    }

    .dot {
      cursor: default;
      background-color: var(--disabledTodo);
      border: var(--disabledTodo) solid 1px;

      &:after {
        background-color: var(--disabledTodo);
        left: 2px;
        top: 2px;
        width: 8px;
        height: 8px;
        border: solid var(--disabledTodo) 3px;
        border-radius: 50%;
      }
    }

    input:checked ~ .dot {
      background-color: var(--disabledTodo);
      border: var(--disabledTodo) solid 1px;
    }
  }
}
