.account-page {
  display: grid;
  grid-template-columns: 180px 1fr;
  column-gap: 24px;
  margin-bottom: 104px;

  @media (max-width: 700px) {
    grid-template-columns: 100%;
    column-gap: 0;
    padding: 12px;
  }

  h1 {
    grid-column: 1 / 3;
    font-size: 3.6rem;
    text-align: center;
    margin-top: 24px;
    margin-bottom: 48px;
  }

  h2 {
    margin-top: 0;
  }
}

.nav-links {
  border-right: 2px solid var(--tertiary);
  margin-bottom: 24px;
  max-height: calc(100vh - 262px);
  display: flex;
  flex-direction: column;

    @media (max-width: 699px) {
    display: flex;
    flex-direction: row;
    justify-content: center;
    grid-column: 1 / 3;
    border: none;
    margin-top: -24px;
    margin-bottom: 24px;
  }

  .nav-item {
    font-size: 2rem;
    height: 40px;
    line-height: 40px;
    padding-left: 12px;
    cursor: pointer;

    @media (max-width: 699px) {
      font-size: 2rem;
      height: 40px;
      line-height: 40px;
      padding: 0 4px;
      margin-right: 8px;
      cursor: pointer;
    }

    &:hover {
      background: var(--tertiary);
      color: var(--textLight);
      transition: .5s all;
    }

    &--active {
      font-weight: bold;
      color: var(--textLight);
      background: var(--tertiary);
      height: fit-content;

      @media (max-width: 699px) {
        font-weight: bold;
        color: var(--tertiary);
        background: inherit;
      }
    }

    &--disabled {
      pointer-events: none;
      color: var(--textMid);
      cursor: default;

      @media (max-width: 699px) {
        display: none;
      }
    }

    &__sub-menu {
      height: 0;
      overflow: hidden;

      &--active {
        height: fit-content;
      }

      &__item {
        padding-left: 12px;
        color: var(--textLight);
        font-size: 1.6rem;
        font-weight: 400;

        &--active {
          font-weight: 800;
          font-size: 1.8rem;
        }

        &:hover {
          font-weight: 800;
        }
      }
    }


    &--logout {
      display: flex;
      align-items: center;
      column-gap: 4px;
      width: 100%;
      margin-top: 40px;

      @media (max-width: 699px) {
        width: inherit;
      }

      svg {
        fill: var(--textDark);
      }

      &:hover {
        svg {
          transition: .5s all;
          fill: var(--textLight);
        }
      }
    }
  }
}