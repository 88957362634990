.ami-description-item {
  border: 1px solid var(--tertiary50);
  border-radius: 5px;
  display: flex;
  height: 48px;
  margin-bottom: 4px;

  &--error {
    border: 1px solid var(--error);
  }

  &__label {
    line-height: 48px;
    padding: 0 12px;
    width: 40%;
    background: var(--tertiary50);
    font-weight: bold;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &__value {
    line-height: 48px;
    padding: 0 12px;
    width: 60%;

    &--not-editable {
      cursor: default;
      color: var(--textMid);
    }

    input {
      height: 100%;
      width: 90%;
      border: none;
      background: transparent;

      &::placeholder {
        opacity: 0.5;
      }
    }
  }

  &__error {
    color: var(--error);
    margin-bottom: 8px;
  }
}