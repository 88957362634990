.options-page {
  display: grid;
  grid-template-columns: 1fr 442px 1fr;
  column-gap: 10%;

  @media (max-width: 1250px) {
    grid-template-columns: 1px 600px 1fr;
    column-gap: 5%;
  }

  @media (max-width: 900px) {
    grid-template-columns: 1px 1fr;
    column-gap: 0;
  }

  &__info {
    @media (max-width: 900px) {
      grid-column: 2;
      margin-top: 24px;
    }
  }

  &__content {
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 24px;

    &__full-width {
      grid-column: 1 / 3;
    }
  }
}
