.details-page {
  input {
    width: 100%;
  }

  &__main-container {
    &__details-container {
      grid-column: 2 / 3;
    }

    &__courier-information-card {
      grid-row: 4;
      grid-column: 2;
      margin-top: 24px;
      margin-bottom: 60px;

      p {
        margin-bottom: 24px;
      }
    }
  }

  .horizontal-card {
    @media (max-width: 550px) {
      display: flex;
      flex-direction: column;
    }
  }

  .collection-details,
  .shipper-details,
  .consignee-details,
  .broker-select-details {
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 12px;
    margin-top: 24px;
    margin-bottom: 24px;
  }

  &__address-type {
    grid-column: 1 / 3;
    margin-top: -26px;
    margin-bottom: 24px;
    opacity: .7;
    font-weight: bold;
  }

  &__error-message {
    grid-row: 2;
    grid-column: 2;
    width: 87%;
    margin-top: 24px;
  }
  @media (max-width: 600px) {
    padding-right: 24px;
  }

  &__button-container {
    grid-row: 3;
    grid-column: 2;
    display: flex;
    justify-content: flex-end;
    margin-right: 47px;
    margin-top: 24px;

    @media (max-width: 600px) {
      flex-direction: column-reverse;
      margin: 24px 48px;
    }

    :first-child {
      margin-right: 8px;

      @media (max-width: 600px) {
        margin-right: 12px;
      }
    }

    > * {
      @media (max-width: 600px) {
        margin: 12px 12px 0 12px;
      }
    }
  }
}

// Folder-wide classNames
.details-container__title {
  font-family: 'Montserrat', sans-serif;
  font-weight: 700;
  font-size: 2rem;
  line-height: 24px;
  color: var(--secondary);
  margin-bottom: 24px;
}

@media (max-width: 550px) {
  #details-page-start-over-btn {
    min-width: 150px;
  }
}

.details-page__country {
  grid-column: 1 / 3;
}

.save-address__checkbox {
  grid-column: 1 / 3;
}

.details-page__checkbox {
  grid-column: 1 / 3;
  margin-bottom: 24px;
}

.tax-numbers-wrapper {
  grid-column: 1 / 3;
}

.tax-numbers-add-text {
  grid-column: 1 / 3;
  margin: -12px 0 24px 0;
  color: var(--primary);
  cursor: pointer;
}

.total-address-line-error {
  grid-column: 1 / 3;
  margin-bottom: 24px;
  margin-top: -12px;
  color: var(--error);
}