.wl-banner-edit {
  padding: 24px;

  form {
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 24px;

    > * {
      width: 100%;
    }

    input {
      width: 50%;
    }

    textarea {
      border: 1px solid var(--disabledTodo);
      width: 100%;
      min-height: 100px;

      &::placeholder,
      &::placeholder {
        color: var(--textMid);
      }
    }
  }

  &__dates {
    grid-column: 1;
    display: flex;
    column-gap: 24px;

    > * {
      width: 100% !important;
    }

    input {
      width: 100% !important;
    }
  }

  &__btns {
    display: flex;
    column-gap: 8px;
    justify-content: flex-end;
  }
}