.bulk-booking-finish-page {
  text-align: center;

  > * {
    margin-bottom: 24px;
  }

  p {
    margin-bottom: 8px;

    @media (max-width: 500px) {
      font-size: 1.6rem;
    }
  }

  &__title {
    font-size: 3.6rem;
    font-weight: bold;
    margin: 32px 0 12px 0;

    @media (max-width: 500px) {
      font-size: 2.8rem;
    }
  }

  &__content {
    width: 40%;
    margin: 0 auto;
    text-align: left;
    margin-top: 60px;
    position: relative;

    @media (max-width: 900px) {
      width: 90%;
    }

    &__title {
      font-size: 3.6rem;
      font-weight: bold;
      margin: 32px 0 0px 0;
    }

    &__divide {
      height: 3px;
      width: 100%;
      margin-bottom: 24px;
    }

    &__text-cont {
      display: grid;
      grid-template-columns: 150px 1fr;

      @media (max-width: 500px) {
        display: grid;
        grid-template-columns: 1fr;
      }
    }
  }
}