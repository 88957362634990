.shipment-piece {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 24px;

  @media (max-width: 780px) {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    border-bottom: 1px dashed var(--textMid);
    margin-bottom: 24px;
    padding-bottom: 24px;
  }
  @media (max-width: 550px) {
    grid-template-columns: 1fr 1fr;
  }

  > * {
    margin-right: 8px;
  }

  .ami-form-element {
    margin-bottom: -4px;
  }

  &__input {
    width: 100% !important;
    max-width: 175px;
    min-width: 110px;
  }

  &__icon-container {
    width: 69px;
    display: flex;
  }

  &__error {
    position: absolute;
    bottom: 12px;
    left: 0;
    color: var(--error);
  }
}