.iconBtnWrapper {
  background: transparent;
  border: transparent;
  display: inline-block;

  &:focus-visible {
    outline: 1px solid var(--textMid);
  }
}

.icon {
  height: 18px;
  width: 18px;

  > * {
    fill: inherit;
  }
}