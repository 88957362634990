.app {
  min-height: 100vh;
  position: relative;
  overflow: hidden;
  background: var(--background);
}

.mobile-navigation-container {
  position: fixed;
  bottom: 0;
  left: 0;
}

.wl-version-number {
  position: fixed;
  bottom: 4px;
  left: 4px;
  color: var(--textMid);
  font-size: 1.2rem;
}