.customise-page {
  padding-right: 24px;
  display: grid;
  grid-template-columns: 1fr 350px;
  column-gap: 24px;

  @media (max-width: 699px) {
    display: block;
  }

  h2 {
    grid-column: 1 / 3;
  }

  h3 {
    margin-bottom: 0;
  }

  &__picker {
    display: none;
  }

  &__content {

    &__color-cont {
      display: flex;
      column-gap: 12px;

      @media (max-width: 699px) {
        flex-direction: column;
      }

      &__color-box {
        width: 145px;
        height: 44px;
        border-radius: 5px;
        color: var(--textLight);
        font-size: 1rem;
        line-height: 44px;
        padding-left: 4px;
        margin-top: 2px;

        p {
          font-size: 1rem;
        }

        &:first-child {
          cursor: pointer;
        }
      }
    }

  }

  .preview-box {
    grid-row: 2;
    grid-column: 2;

    h3 {
      margin-top: 0;
      margin-bottom: 12px;
    }

    > p {
      margin-bottom: 8px;
    }

    &__btns {
      margin-top: 24px;

      > * {
        width: 100%;
        margin-bottom: 12px;
      }
    }
  }

  &__upload-cont {
    grid-column: 1;
  }

  .mobile-slider {
    position: fixed;
    right: -23px;
    top: 200px;
    background: var(--secondary);
    transform: rotate(270deg);
    padding: 4px 8px;
    border-radius: 5px 5px 0 0;
    cursor: pointer;

    p {
      color: var(--textLight);
    }

    @media (min-width: 699px) {
      display: none;
    }
  }
}

