.commodities-wrapper {
  grid-column: 1 / 3;

  &__sub-commodities-container {
    display: grid;
    grid-template-columns: 50% 50%;
    margin-top: 24px;

    @media (max-width: 900px) {
      grid-template-columns: 100%;
      row-gap: 24px;
    }

    &__add-commodity {
      color: var(--primary);
      line-height: 28px;
      display: flex;
      cursor: pointer;
      width: fit-content;
      height: fit-content;
      margin-top: -12px;

      svg {
        font-size: 3rem;
        color: inherit;
        margin-right: 4px;
        transition: transform .3s;
        transform: rotate(45deg);
        height: 12px;
        width: 12px;
      }

      &:hover svg {
        transform: rotate(135deg);
        transition: transform .3s;
      }
    }

    &__totals-grid {
      display: grid;
      grid-template-columns: 50% 50%;
      row-gap: 8px;

      span {
        color: var(--textMid);
      }

      .commodity-total-red {
        color: var(--error);
      }
    }

  }
}