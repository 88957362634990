#storm-chat-widget-container {
    display: flex;
    position: fixed;
    bottom: 12px;
    right: 15px;
    justify-content: center;
    align-items: center;
}

#storm-chat-widget {
    position: fixed;
    bottom: 0;
    right: 0;
    height: 60%;
    width: 30%;
}

.storm-chatButton {
    background-color: rgb(35,73, 112);
    font-family: 'Montserrat', sans-serif;
    font-weight: bold;
    font-size: 1.6rem;
    padding: 5px;
    color: #FFF;
    border: 1px solid #669;
    border-radius: 2px;
}

#storm-closeChatButton, #storm-newChatButton {
    position: fixed;
    width: 25px;
    bottom: 56%;
    right: 1%;
    z-index: 10;
    background-color: lightslategrey;
    align-content: center;
    justify-items: center;
}

#storm-newChatButton {
    right: 40px !important;
    width: 100px;
}

@media (max-width: 900px) {
    div#chat-widget-container{
        bottom: 60px !important;
        max-height: 90% !important;
    }

    #storm-chat-widget-container {
        position: fixed;
        bottom: 75px !important;
        max-height: 90% !important;
        right: 15px;
    }

    #storm-chat-widget {
        width: 90%;
    }
}