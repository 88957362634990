.popover {
  max-width: 250px;
  height: max-content;
  background: var(--background);
  color: var(--text);
  border-radius: 5px;
  padding: 12px 16px;
  position: absolute;
  z-index: 100;
  overflow: hidden;
  transition: opacity .3s;
  box-shadow: 0px 2px 3px 1px rgba(0, 0, 0, 0.25);
}