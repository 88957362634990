.upload-grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin-top: 24px;
}

.drag-and-drop-box {
  grid-column: 1 / 3;
  height: 80px;
  border: 1px dashed var(--textMid);
  font-family: 'Montserrat', sans-serif;
  font-weight: 700;
  font-size: 2rem;
  display: inline-flex;
  align-items: center;
  justify-content: center;

  &--active {
    color: var(--primary);
    border: 2px dashed var(--primary);
  }
}

.invoice-upload-button {
  display: inline;
  margin-left: 24px;
  margin-top: 24px;
}

.upload-input-btn {
  display: none;
}

.upload-alert {
  margin-top: 24px;
}

.uploaded-file {
  margin-top: 12px;

  &:hover {
    font-weight: bold;
  }
}

.document-delete-icon {
  float: right;
  cursor: pointer;
}