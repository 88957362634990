table {
  width: 100%;
  border-collapse: collapse;

  tr > * {
    color: var(--text);
    padding: 8px;
  }

  th {
    font-weight: bold;
    font-size: 1.6rem;
    text-align: left;
  }
}

.styled-table {
  th {
    background: var(--tertiary);
    color: white;
  }

  tbody tr:nth-child(even) {
    background: var(--disabledTodo);
  }
}
