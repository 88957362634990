.auth-page {
  display: grid;
  grid-template-columns: 1fr 455px 1fr;

  @media (max-width: 500px) {
    grid-template-columns: 24px 1fr 24px;
  }
}

.auth-container {
  grid-column: 2;
  display: flex;
  flex-direction: column;
  align-items: baseline;
  margin-top: 20vh;
  margin-bottom: 100px;

  h1 {
    font-size: 4rem;
  }

  &__logo {
    align-self: center;
    margin-bottom: 24px;
  }

  &__text {
    margin-bottom: 24px;
  }

  .ami-form-element {
    margin-bottom: 12px;
  }

  &__forgot-title {
    font-size: 1.4rem;
    cursor: pointer;
    margin-top: 16px;
    margin-bottom: 8px;

    &:hover {
      color: var(--primary);
    }
  }

  &__forgot-text {
    font-size: 1.4rem;
    text-align: left;

    a {
      text-decoration: underline;
      color: var(--primary);
      font-size: inherit;
    }
  }
}
