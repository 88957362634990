.summary-slider {
  position: fixed;
  width: 266px;
  right: 24px;
  background: var(--background);
  border: 1px solid var(--secondary);
  border-radius: 0px 25px;
  text-align: center;
  padding: 24px 12px;
  z-index: 100;
  cursor: pointer;

  @media (max-width: 500px) {
    right: 8px;
  }

  &__service-title {
    font-family: 'Montserrat', sans-serif;
    font-weight: bold;
  }

  &__to-from-container {
    margin-top: 24px;
    font-weight: bold;
    display: flex;
    justify-content: center;

    span {
      margin: 0 12px;
    }
  }

  &__ex-vat {
    text-align: start;
    font-size: 1.2rem;
    margin-bottom: 4px;
  }

  &--contracted {
    position: fixed;
    width: 100px;
    height: 80px;
    right: 24px;
    background: var(--background);
    border: 1px solid var(--secondary);
    border-radius: 0px 25px;
    text-align: center;
    padding: 8px;
    overflow: hidden;
    z-index: 100;
    cursor: pointer;

    @media (max-width: 500px) {
      right: 8px;
    }

    > * {
      visibility: hidden;
    }

    &__total-container {
      visibility: visible;
      position: absolute;
      top: 4px;
      left: 8px;

      :last-child {
        font-family: 'Montserrat', sans-serif;
        font-weight: 700;
        font-size: 1.8rem;
      }
    }
  }

  &__expand-icon {
    visibility: visible;
    position: absolute;
    bottom: 2px;
    right: 8px;

    p {
      color: var(--secondary);
      font-size: 1.2rem;
      margin-top: -8px;
    }
  }
}

