.mobile-navigation-mask--hidden {
  display: none;
}

.mobile-navigation-mask--visible {
  background: var(--backgroundDark);
  opacity: .5;
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
}

.mobile-account-menu {
  &--closed {
    width: 100vw;
    position: absolute;
    bottom: -400px;
    left: 0;
    background: var(--background);
    transition: all .5s;
  }

  &--open {
    width: 100vw;
    position: absolute;
    bottom: 0;
    left: 0;
    background: var(--background);
    transition: all .5s;
    z-index: 100;
  }

  &__item {
    text-align: center;
    font-size: 2rem;
    margin: 24px;
    cursor: pointer;

    a {
      text-align: center;
      font-size: 2rem;
      margin: 24px;
      cursor: pointer;
      color: inherit;
    }

    &--disabled {
      opacity: .5;
      pointer-events: none;
      cursor: default;
    }

    &--primary {
      color: var(--primary);
      font-weight: bold;
    }
  }
}

.mobile-navigation {
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 100vw;
  height: 63px;
  background-color: var(--tertiary);

  > * {
    margin: 0;
    color: var(--textLight);
    font-family: 'Montserrat', sans-serif;
    font-weight: bold;
    font-size: 1rem;
    cursor: pointer;
    text-align: center;
    padding: 0 2px;
    display: flex;
    flex-direction: column;
  }

  &__link--disabled {
    pointer-events: none;
    cursor: default;
    opacity: .5;
  }
}