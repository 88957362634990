.ami-form-element {
  margin-bottom: 24px;
  position: relative;

  &--error {
    input {
      border-color: var(--error);
    }

    select {
      border-color: var(--error);
    }
  }

  &__label {
    margin-bottom: 4px;
    color: var(--textMid);

    > * {
      color: inherit;
    }
  }

  &__error-message {
    margin-top: 2px;
    color: var(--error);
  }
}