.account-templates__template {
  position: relative;
  height: 38px;
  line-height: 38px;
  border-bottom: 1px solid var(--disabledTodo);
  display: flex;
  justify-content: space-between;
  cursor: default;
  padding: 0 8px 0 8px;
  border-radius: 5px;
  border-bottom: 1px solid var(--disabledTodo);

  @media (max-width: 700px) {
    padding: 0;
  }

  &:hover {
    background-color: var(--backgroundHover);
  }

  p {
    overflow: hidden;
  }

  &__icons {
    display: flex;
    align-items: center;
    height: 43px;

    @media (max-width: 700px) {
      min-width: 50px;
    }

    > * {
      cursor: pointer;
    }
  }

  &__spinner {
    position: absolute;
    width: 100%;
    display: flex;
    justify-content: center;
  }

  &__text {
    display: flex;
    align-items: center;

    &--disabled {
      opacity: 0.3;
      pointer-events: none;
    }

    &__tag {
      margin-left: 12px;
      font-size: 1.4rem;
      color: var(--textLight);
      background-color: var(--tertiary);
      border-radius: 5px;
      padding: 4px;
      line-height: 14px;
      cursor: pointer;
    }
  }
}