.multi-button {
  display: flex;
  font-family: 'Montserrat', sans-serif;
  font-weight: 700;

  > * {
    background: var(--background);
    border: 0.707692px solid var(--secondary50);
    box-sizing: border-box;
    border-radius: 3.5px 0px 0px 3.5px;
    flex-grow: 1;
    text-align: center;
    font-size: 1.4rem;
    line-height: 20px;
    cursor: pointer;

    &:hover {
      background: var(--secondary50);
    }
  }
}