.drop-in-details {
  margin-top: 24px;

  &__alert {
    margin-bottom: 24px;
    p {
      margin-bottom: 12px;
    }
  }

  &__details-container {
    &__open-between {
      color: var(--primary);
      margin-bottom: 12px;
    }

    &__company-name {
      margin-bottom: 4px;
      font-size: 1.8rem;
      font-weight: bold;
    }

    &__telephone-number {
      margin-top: 12px;
    }
  }

  &__spinner {
    margin: 0 auto;
    display: block;
    width: 50px;
    height: 50px;
  }
}