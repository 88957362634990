.switch {
  height: 24px;
  display: grid;
  column-gap: 2px;
  grid-template-columns: 1fr 42px 1fr;

  @media (max-width: 650px) {
    float: left;
  }

  &--no-titles {
    grid-template-columns: 42px;
  }

  :first-child {
    justify-self: flex-end;
  }

  &__toggle {
    width: 100%;
    background-color: var(--tertiary);
    border-radius: 24px;
    position: relative;
    cursor: pointer;
    display: flex;
    align-items: center;
    transition: .3s all;

    &:hover {
      background: var(--primary50);
    }

    &--0 {
      &::before {
        left: 3px;
      }
    }

    &--1 {
      &::before {
        right: 3px;
      }
    }

    &::before {
      content: ' ';
      position: absolute;
      width: 18px;
      height: 18px;
      border-radius: 50%;
      background-color: var(--background);
      top: 3px;
    }
  }
}