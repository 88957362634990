.invoice-card {
  margin-top: 24px;
  margin-bottom: 24px;

  a {
    margin-bottom: 12px;
    display: block;
  }
}

.invoice-details-row,
.generate-invoice-form {
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 24px;
}