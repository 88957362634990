.pop-confirm {
  height: max-content;
  display: flex;
  justify-content: center;
  background: var(--background);
  color: var(--text);
  border-radius: 5px;
  padding: 12px 16px;
  position: absolute;
  z-index: 100;
  overflow: hidden;
  transition: opacity .3s;
  max-width: 400px;
  min-width: 400px;
  align-items: flex-start;
  flex-direction: column;
  box-shadow: 0px 2px 3px 1px rgba(0, 0, 0, 0.25);

  &__button-container {
    display: flex;
    justify-content: flex-end;
    margin-top: 24px;
    width: 100%;

    button {
      margin-left: 8px;
      z-index: 100;
    }
  }
}