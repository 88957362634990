.insurance-card {
  label {
    display: block;
    position: relative;
    padding-left: 28px;
    margin-bottom: 12px;
    cursor: pointer;
    font-size: 1.6rem;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }

  &__description-text {
    margin-bottom: 24px;
  }

  &__insure-label-span {
    color: var(--success);
    font-weight: bold;
  }
  &__do-not-insure-label-span {
    color: var(--error);
    font-weight: bold;
  }

  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }
}
