.quote {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 8px -8px;
  padding: 0 8px;

  &:hover {
    background-color: var(--backgroundHover);
  }

  &__details {
    &__transport-method {
      display: flex;

      :first-child {
        margin-right: 2px;
      }
    }

    &__service-type {
      font-weight: bold;
      color: var(--secondary);
    }
  }


  &__price {
    max-height: 24px;
    overflow: hidden;
    transition: .7s all;
    text-align: end;

    &__total {
      font-family: 'Montserrat', sans-serif;
      font-weight: 700;
      font-size: 2rem;
      line-height: 24px;
      margin-bottom: 4px;
      text-align: end;
      display: block;
    }

    &__breakdown {
      display: grid;
      grid-template-columns: 2fr 1fr;
      grid-gap: 4px;

      & > * {
        font-size: 1.4rem;
      }
    }
  }

  &:hover .quote__price {
    padding-top: 8px;
    padding-bottom: 8px;
    max-height: 150px;
    transition: 1.5s max-height;
  }
}