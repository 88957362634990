.confirm-page {
  &__button-container {
    grid-row: 3;
    grid-column: 2;
    display: flex;
    justify-content: flex-end;
    margin-right: 47px;
    margin-top: 24px;

    @media (max-width: 600px) {
      flex-direction: column-reverse;
      margin: 24px 48px 72px 48px;
    }

    :first-child {
      margin-right: 8px;
      @media (max-width: 600px) {
        margin-right: 12px;
      }
    }
    > * {
      @media (max-width: 600px) {
        margin: 12px 12px 0 12px;
      }
    }
  }

  &__issues-slider {
    position: fixed;
    width: 290px;
    right: 24px;
    background: var(--background);
    border: 1px solid var(--secondary);
    border-radius: 0px 25px;
    padding: 24px;

    &__main-content {
      overflow-y: auto;
      overflow-wrap: anywhere;
      max-height: 300px;

      &__error {
        margin-bottom: 8px;
      }
    }
  }

  &__address-type {
    grid-column: 1 / 3;
    margin-top: -26px;
    margin-bottom: 24px;
    opacity: .7;
    font-weight: bold;
  }
}

.confirm-container {
  grid-column: 2 / 3;

  .horizontal-card {
    margin-bottom: 24px;
    position: relative;

    @media (max-width: 450px) {
      margin-left: -10%;
      margin-right: -10%;
    }
  }

  &__top-row {
    display: grid;
    grid-template-columns: 1fr 2fr;
    text-align: center;

    @media (max-width: 450px) {
      grid-template-columns: 1fr;
    }
  }
  &__service-title {
    font-family: 'Montserrat', sans-serif;
    font-weight: bold;
  }

  &__to-from-container {
    margin-top: 24px;
    font-weight: bold;
    display: flex;
    justify-content: center;

    span {
      margin: 0 12px;
    }
  }

  &__pieces {
    margin-top: 24px;
  }

  &__edit-btn {
    position: absolute;
    right: 12px;
    top: 12px;
    min-width: 85px;
  }

  &-tax-details {
    margin-top: 12px;
  }

  &__shipment-details{
    &__grid {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      row-gap: 16px;
    }

    &__insured {
      color: var(--success);
      display: flex;
      align-items: center;
      margin-top: 16px;
    }

    &__not-insured {
      color: var(--error);
      display: flex;
      align-items: center;
      margin-top: 16px;
    }

  }

  &__address-details-container {
    margin-bottom: 12px;

    h4 {
      margin-top: 4px;
      margin-bottom: 2px;
    }
  }

  &__preferences__content {
    display: flex;
    flex-wrap: wrap;

    > * {
      margin-right: 24px;
    }
  }

  &__checkbox {
    margin: 24px;

    span {
      color: var(--primary);
      cursor: pointer;
    }

    &--wl-terms {
      display: flex;
      align-items: center;
    }
  }

  &__collection-details span,
  &__drop-in-details span,
  &__shipment-details__grid span,
  &__preferences span,
  &__consignee-details span,
  &__broker-details span {
    color: var(--textMid);
    font-size: 1.4rem;
  }
}





