.weight-break {
  display: flex;
  column-gap: 4px;
  margin-top: 24px;

  .ami-form-element {
    margin-bottom: 0;
  }

  .input {
    width: 100px;
  }
}