.re-quoting-container {
  margin-left: 50%;
  transform: translateX(-50%);
  display: flex;
  flex-direction: column;
  align-items: center;
}

.quotes-page {
  display: grid;
  grid-template-columns: 300px 1fr 24px;
  margin-bottom: 400px;

  @media (max-width: 1050px) {
    grid-template-columns: 12px 1fr 12px;
   }

  @media (max-width: 650px) {
    grid-template-columns: 12px 1fr 12px;
  }

  &__marketing-image {
    grid-column: 1;
    margin: 24px;

    @media (max-width: 1050px) {
      margin: 180px 0 0 24px;
      width: 150px;
    }
  }

  &__quotes-container {
    display: flex;
    flex-direction: column;
    margin-bottom: 200px;
    grid-column: 2 / 3;
    margin-top: -12px;

    &__alert {
      margin-bottom: 24px;

      p {
        margin-bottom: 12px;
      }
    }

    .alert {
      margin: 24px 0;
    }
  }
}

.time-frame {
  width: 100%;

  &__title {
    display: flex;
    justify-content: space-between;
    margin: 0 0 12px 0;
    padding: 4px;
    border-bottom: 3px solid var(--secondary);

    @media (max-width: 1000px) {
      width: 100.5%;
    }

    &__main {
      display: flex;
      flex-direction: column;

      > * {
        display: flex;
      }
    }

    &__ex-vat {
      font-size: 1.2rem;
    }

    p {
      font-family: 'Montserrat', sans-serif;
      font-weight: 700;
      font-size: 2rem;
      color: var(--secondary);
    }

    span {
      font-weight: 400;
      font-family: 'Lato', sans-serif;
    }
  }

  &__quotes {

    @media (max-width: 650px) {
      display: block;
      width: 95vw;
    }

    &--hidden {
      display: none;
    }
  }
}