.preview-widget {
  position: fixed;
  position: fixed;
  left: 50%;
  bottom: 48px;
  transform: translateX(-50%);
  background: var(--warning50);
  padding: 12px;
  border: 4px solid var(--warning);
  border-radius: 15px;
  z-index: 1000;

  h4 {
    margin: 12px 0;
  }

  p {
    margin-bottom: 12px;
  }
}