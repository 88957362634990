.ami-select,
.ami-select-default {
  font-family: 'Lato', sans-serif;
  padding-left: 8px;
  font-size: 1.4rem;
  height: 32px;
  width: auto;
  border: 1px solid var(--disabledTodo);
  background: var(--background);
  color: var(--text);
  border-radius: 5px;
  background: url('../../ui-components/icon/pngs/down_chevron.png') no-repeat;
  background-position: top 12px right 12px;
  appearance: none;
  cursor: pointer;

  &::placeholder,
  &::placeholder {
    color: var(--textMid);
  }

  &:hover + .ami-select__clear-btn::before {
    opacity: 1;
    left: -40px;
  }

  &__clear-btn {
    z-index: 99;
    cursor: pointer;
    color: var(--textMid);
    position: absolute;
    transform: translate(-23px, 12px);
    background-color: var(--background);

    &::before {
      opacity: 0;
      position: absolute;
      left: 0;
      content: 'Clear';
      transition: all .7s ease;
    }

    &:hover::before {
      opacity: 1;
      left: -40px;
    }
  }

  &--large {
    height: 44px;
    font-size: 1.6rem;
    background: url('../../ui-components/icon/pngs/down_chevron.png') no-repeat;
    background-position: top 17px right 12px;
  }

  &--disabled {
    background: url('../../ui-components/icon/pngs/down_chevron.png') no-repeat var(--disabledTodo);
    background-position: top 17px right 12px;
    cursor: default;
  }

  &__dropdown {
    position: absolute;
    width: 100%;
    border-radius: 5px;
    margin-top: 4px;
    overflow: auto;
    overflow-x: hidden;
    border: 1px solid var(--disabledTodo);
    background: var(--background);
    z-index: 100;
    max-height: 200px;

    &--hidden {
      position: absolute;
      max-height: 0px;
      overflow: hidden;
    }
  }

  &__spinner {
    display: flex;
    align-items: center;
  }
}

select:focus {
  outline: none;
  border: 1px solid var(--primary);
}
