.quote-summary {
  grid-column: 1;
  grid-row: 1;
  width: 250px;
  margin: 0 24px;
  padding: 12px 18px;
  position: relative;

  @media (max-width: 1050px) {
   display: none;
  }

  &__tradelane-switch {
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: center;
    cursor: pointer;
    z-index: 1;
    left: 4px;
    top: 4px;
    transition: .5s all;

    &:hover {
      transform: rotate(180deg);
      transition: .5s all;
      transform-origin: center;
    }
  }

  &__to-from-container {
    margin-top: 24px;
    font-weight: bold;
    display: grid;
    grid-template-columns: 1fr 24px 1fr;
    text-align: center;
    margin-bottom: 24px;

    @media (max-width: 650px) {
      margin: 0 0 12px 0;
    }

    span {
      margin: 0 12px;
    }
  }

  p {
    margin-bottom: 4px;
  }
}