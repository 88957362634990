.account-templates {
  max-width: 1500px;
  margin-right: 12px;

  &__top-row {
    display: flex;
    justify-content: space-between;
    margin-bottom: 24px;

    &__add-new-template-btn {
      color: var(--primary);
      white-space: nowrap;
      cursor: pointer;
    }
  }
}