.ami-select-option {
  padding: 12px;
  cursor: pointer;
  border-bottom: 1px solid var(--disabledTodo);

  &:hover {
    background: var(--backgroundHover);
  }

  &--disabled {
    opacity: 0.3;
    cursor: default;
  }
}
