.review-page {

  &__multi-select-banner {
    background: var(--error);
    border-radius: 25px;
    padding: 8px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: var(--textLight);
    fill: var(--textLight);
    margin-bottom: 24px;

    p {
      color: var(--textLight);

      span {
        background: var(--background);
        border-radius: 50%;
        width: 26px;
        height: 26px;
        line-height: 26px;
        text-align: center;
        display: inline-block;
        font-weight: bold;
      }
    }

    &__icon {
      cursor: pointer;
      margin-right: 8px;
    }

  }

  &__table {
    margin-bottom: 100px;

    &__checkbox {
      margin-right: 8px;

      .ami-checkbox {
        padding-left: 0;
        transform: translateY(-10px);
      }

      &--header .ami-checkbox span {
        border: var(--background) solid 1px;
      }
    }

    &__icons {
      display: flex;
      justify-content: center;

      &__icon {
        cursor: pointer;
        padding: 0 4px;
      }
    }

  }

  &__btn-container {
    display: flex;
    justify-content: flex-end;
    margin-top: 24px;
    position: absolute;
    bottom: 100px;
    right: 48px;

    > * {
      margin-left: 8px;
    }

    &--collapsed {
      position: fixed;
      right: 0;
      bottom: 150px;
      display: flex;
      flex-direction: column;
      align-items: flex-end;

      > * {
        margin-top: 8px;
        min-width: 48px;
      }

    }
  }
}