.input-container {
  width: 100%;

  &--freight-charge {
    grid-column: span 4;
  }
}

.input,
.input-default {
  font-family: 'Lato', sans-serif;
  padding-left: 8px;
  font-size: 1.4rem;
  height: 32px;
  width: auto;
  border: 1px solid var(--disabledTodo);
  background: var(--background);
  color: var(--text);
  border-radius: 5px;

  &:focus {
    outline: none;
    border: 1px solid var(--primary);
  }

  &::placeholder {
    color: var(--textMid);
  }

  &--disabled {
    background: var(--disabledTodo);
    color: var(--textMid);
  }

  &--large {
    height: 44px;
    font-size: 1.6rem;
  }
}

.input-container input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}