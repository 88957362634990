.tracking-page {
  max-width: 1250px;
  margin: 80px auto 120px auto;

  @media (max-width: 1400px) {
    max-width: 90%;
  }
  
  @media (max-width: 550px) {
    margin-top: 40px;
  }

  &__tracking-card {
    grid-column: 2;

    &__bookings-card {
      margin-top: 24px;
      position: relative;

      &__tracking-filter {
        position: absolute;
        right: 24px;
        top: 24px;

        @media (max-width: 640px) {
          width: 35%;
        }

        &__spinner {
          margin: 0 auto;
          display: block;
        }
      }
    }
  }
}