.account-addresses__address {
  position: relative;
  height: 38px;
  line-height: 38px;
  border-bottom: 1px solid var(--disabledTodo);
  display: flex;
  justify-content: space-between;
  cursor: default;
  padding: 0 8px 0 8px;
  border-radius: 5px;

  @media (max-width: 700px) {
    padding: 0;
  }

  &:hover {
    background-color: var(--backgroundHover);
  }

  p {
    overflow: hidden;
  }

  &__icons {
    min-width: 46px;

    @media (max-width: 700px) {
      min-width: 50px;
    }

    > * {
      cursor: pointer;
    }
  }

  &__spinner {
    position: absolute;
    width: 100%;
    display: flex;
    justify-content: center;
  }

  &__disabled {
    opacity: 0.3;
    pointer-events: none;
  }
}






