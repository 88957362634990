.spinner {
  position: relative;
  display: inline-block;
  width: 100px;
  height: 100px;
  animation: spin 1.5s infinite alternate-reverse;

  &__circle {
    border-radius: 50%;
    position: absolute;

    &--pink {
      width: 45%;
      height: 45%;
      margin: 50%;
      transform: translate(-50%, -50%);
      background-color: var(--primary);
    }

    &--darkblue {
      width: 25%;
      height: 25%;
      top: 38%;
      left: 37%;
      background-color: var(--secondary);
      animation: expand-darkblue 1.5s infinite;
    }

    &--lightblue {
      width: 25%;
      height: 25%;
      top: 38%;
      left: 37%;
      background-color: var(--tertiary);
      animation: expand-lightblue 1s infinite;
    }

    &--white {
      background-color: var(--background);
    }
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  5% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(-360deg);
  }
}

@keyframes expand-darkblue {
  0% {
    transform: translateY(0%);
  }
  50% {
    transform: translateY(-150%);
  }
  100% {
    transform: translateY(0%);
  }
}

@keyframes expand-lightblue {
  0% {
    transform: translateY(0%);
  }
  50% {
    transform: translateY(150%);
  }
  100% {
    transform: translateY(0%);
  }
}