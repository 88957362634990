:root {
  --primary: #932682;
  --primary75: color-mix(in srgb, var(--primary), white 25%);
  --primary50: color-mix(in srgb, var(--primary), white 50%);
  --secondary: #003B78;
  --secondary75: color-mix(in srgb, var(--secondary), white 25%);
  --secondary50: color-mix(in srgb, var(--secondary), white 50%);
  --tertiary: #85AED9;
  --tertiary75: color-mix(in srgb, var(--tertiary), white 25%);
  --tertiary50: color-mix(in srgb, var(--tertiary), white 50%);
  --error: #EB5757;
  --error50: color-mix(in srgb, var(--error), white 50%);
  --success: #219653;
  --success50: color-mix(in srgb, var(--success), white 50%);
  --text: #333333;
  --textMid: #999999;
  --textLight: #FFFFFF;
  --warning: #F2C94C;
  --warning50: color-mix(in srgb, var(--warning), white 50%);
  --disabledTodo: #EBEBEB;
  --background: #FFFFFF;
  --backgroundDark: #333333;
  --backgroundHover: #bb85b348;
}