.bulk-booking-step {
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;

  &__title {
    font-size: 2rem;
    margin-bottom: 12px;

    @media (max-width: 900px) {
      font-size: 1.7rem;
      margin-bottom: 4px;
    }

    &--todo {
      color: var(--textMid);
    }

    &--active {
      color: var(--primary);
      font-weight: bold;
    }

    &--complete {
      color: var(--secondary);
    }
  }

  &__dashes {
    font-weight: bold;
    transform: translateY(16px);

    @media (max-width: 500px) {
      font-size: 1rem;
    }
  }
}
