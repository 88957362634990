.home-page {
  &__banner {
    grid-column: 1 / 4;
    height: 305px;
    width: 90%;
    border-radius: 0 25px;
    margin: 0 auto;
    background: transparent;

    @media (max-width: 550px) {
      border-radius: 0;
      width: 100%;
    }

    &--empty {
      width: 0;
      height: 200px;
    }
  }

  &__content {
    grid-column: 2 / 3;
    margin-top: -150px;

    @media (max-width: 550px) {
      margin-top: -200px;
    }
  }

  &__error-message {
    margin-top: 24px !important;
  }

  &__search-button {
    float: right;
    margin: 24px 48px 0 0;
  }
}

.home-page__form {
  position: relative;

  &__apply-a-template {
    position: absolute;
    color: var(--tertiary);
    top: 24px;
    right: 24px;
    cursor: pointer;
  }

  &__template-cont {
    margin-bottom: 24px;
    margin-top: 4px;
    background: var(--tertiary50);
    padding: 12px;
    border-radius: 5px;
    border: 1px solid var(--tertiary);

    p {
      margin-bottom: 4px;
    }

    &__entry {
      padding: 6px 12px;
      border-radius: 5px;
      cursor: pointer;
      transition: all .3s;

      &:hover {
        background: var(--tertiary);
      }
    }
  }

  &__template-line {
    display: flex;
    align-items: center;
    column-gap: 12px;
    margin-bottom: 24px;

    .alert {
      flex: 1
    }
  }

  &__tradelane-switch {
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 50px;
    right: 24px;
    transform: translateY(-26px);
    cursor: pointer;
    z-index: 1;

    svg {
      transition: .5s all;
    }

    &:hover svg {
      transition: .5s all;
      transform: rotate(180deg);
    }

    p {
      font-size: 1.2rem;
      color: var(--secondary);
    }
  }

  &__origin,
  &__destination {

    h4 {
      grid-column: 1 / 4;
      margin: 0 0 4px 0;
      font-weight: 400;
      font-size: 18px;
      font-family: 'Lato', sans-serif;
    }

    position: relative;
    display: grid;
    grid-template-columns: 2fr 1fr 1fr;
    column-gap: 4px;
    margin-bottom: 24px;

    @media (max-width: 760px) {
      display: flex;
      flex-direction: column;
    }

    &__results-container {
      position: absolute;
      grid-column: 2 / 3;
      border: 1px solid var(--textMid);
      transform: translateY(116px);
      border-radius: 5px;
      margin-bottom: 24px;
      margin-top: -12px;
      max-height: 300px;
      overflow: auto;
      background: white;
      z-index: 1;
      min-width: 262px;

      > p {
        cursor: pointer;
        text-wrap: nowrap;
        padding: 12px;
        border-bottom: 1px solid var(--textMid);

        &:hover {
          font-weight: bold;
        }
      }
    }
  }

  &__template-select-container {
    width: 100%;
    position: relative;
    margin-bottom: 24px;

    &__template-select {
      width: 100% !important;
    }
  }

  &__origin-residential-check,
  &__destination-residential-check {
    grid-column: 1 / 4;
    margin-top: -16px;
  }
}