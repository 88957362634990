.steps {
  height: 110px;
  width: 616px;
  background-color: var(--background);
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  margin: 24px auto;

  @media (max-width: 900px) {
    height: 92px;
    width: 95%;
    background-color: var(--background);
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    margin: -8px auto 24px auto;
    border-radius: 18px;
  }
}
