.add-edit-address-modal {

  input {
    width: 100%;
  }

  &__spinner {
    margin: 0 auto;
    width: 50px;
    height: 130px;
  }

  &__country,
  &__saved-address-error {
    grid-column: 1 / 3;
  }

  .modal__content form {
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 24px;
    line-height: normal;
  }
}


